import React, { useState, useEffect } from "react";
import CheckMark from "@heroicons/react/solid/CheckIcon";
import arrowSvg from "../../images/pricing-tables/arrow.svg";

const DiscountArrow = () => (
  <div className="absolute hidden md:block -top-6 -left-24">
    <div className="flex flex-col items-center justify-center">
      <p className="font-normal font-dm-sans">Save 20%</p>
      <img src={arrowSvg} className="-mr-4" />
    </div>
  </div>
);

const PricingTab = ({ isYearly, setIsYearly }) => (
  <div className="select-none rounded-l-full rounded-r-full bg-slate-headline bg-opacity-5 p-[5px] max-w-[260px] m-auto relative">
    <div className="flex justify-center">
      <DiscountArrow />
      <div
        onClick={() => setIsYearly(true)}
        className={`w-full cursor-pointer px-8 py-3 transition-all duration-300 rounded-l-full rounded-r-full ${
          isYearly ? "bg-white" : ""
        }`}
      >
        <p> Yearly </p>
      </div>
      <div
        onClick={() => setIsYearly(false)}
        className={`w-full cursor-pointer px-8 py-3 transition-all duration-300 rounded-l-full rounded-r-full ${
          isYearly ? "" : "bg-white"
        }`}
      >
        <p> Monthly </p>
      </div>
    </div>
  </div>
);

const LeftColumn = ({ isYearly }) => (
  <div className="w-full relative p-[32px] rounded-3xl bg-white">
    <div>
      <div className="text-center">
        <div className="my-4">
          <h4 className="text-3xl font-medium">Free</h4>
          <p className="max-w-[243px] m-auto mt-2 opacity-40">
            Perfect to get started
          </p>
          <p
            className="mt-2 font-bold text-desktop-paragraph text-slate-body"
            id="freePrice"
          >
            {isYearly ? "€0 / month" : "€0 / month"}
          </p>
        </div>
      </div>
      <div className="flex justify-center">
        <ul className="">
          <li className="flex items-center justify-around mt-6">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">250 requests</p>
          </li>
          <li className="flex items-center justify-around mt-6">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">
              No simultaneous checks
            </p>
          </li>
          <li className="flex items-center justify-around mt-6">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">Result statuscode</p>
          </li>
          <li className="flex items-center justify-around mt-6">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">Import from CSV</p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">Export to CSV</p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">
              Throttle simultaneous requests
            </p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">Show response time</p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">Prepend url scheme</p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">Add custom headers</p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">
              Select User-Agent string
            </p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">
              Follow up to 5 redirects
            </p>
          </li>
          <li className="flex items-center justify-around mt-6 opacity-20">
            <CheckMark className="w-1/6 h-4 text-slate-blue" />
            <p className="w-5/6 text-desktop-paragraph">Basic SSL validation</p>
          </li>
        </ul>
      </div>
      <div className="mt-8">
        <a
          href="https://app.urlcheckr.com/signup"
          className="flex items-center justify-center w-full py-4 border-2 rounded-lg text-slate-blue border-slate-blue text-desktop-paragraph filter hover:bg-slate-blue hover:text-white"
        >
          <span>Sign up</span>
        </a>
      </div>
    </div>
  </div>
);

const CenterColumn = ({ isYearly }) => {
  const [premiumPriceMonthly, setPremiumPriceMonthly] = useState(0);
  const [premiumPriceYearly, setPremiumPriceYearly] = useState(0);
  useEffect(() => {
    const premiumMonthly = 588287;
    const premiumYearly = 587849;

    window.Paddle.Product.Prices(premiumMonthly, (prices) => {
      setPremiumPriceMonthly(prices.recurring.price.net);
    });

    window.Paddle.Product.Prices(premiumYearly, (prices) => {
      setPremiumPriceYearly(prices.recurring.price.net);
    });
  }, []);

  return (
    <div className="border-[3px] w-full relative bg-white border-slate-blue p-[32px] rounded-3xl">
      {/* <span className="absolute top-6 right-6 text-xs font-bold uppercase text-slate-blue px-[18px] py-[6px] rounded-full">
      Popular
    </span> */}
      <div>
        <div className="text-center">
          <div className="my-4">
            <h4 className="text-3xl font-medium">Premium</h4>
            <p className="max-w-[243px] m-auto mt-2 opacity-40">
              Everything we have to offer
            </p>
            <p
              className="mt-2 font-bold text-desktop-paragraph text-slate-body"
              id="premiumPrice"
            >
              {isYearly
                ? `${premiumPriceYearly} / year`
                : `${premiumPriceMonthly} / month`}
            </p>
          </div>
        </div>
        <div className="flex justify-center">
          <ul className="">
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">Unlimited requests</p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">
                10 simultaneous checks
              </p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">Result statuscode</p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">Import from CSV</p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">Export to CSV</p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">
                Throttle simultaneous requests
              </p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">Show response time</p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">Prepend url scheme</p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">Add custom headers</p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">
                Select User-Agent string
              </p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">
                Follow up to 5 redirects
              </p>
            </li>
            <li className="flex items-center justify-around mt-6">
              <CheckMark className="w-1/6 h-4 text-slate-blue" />
              <p className="w-5/6 text-desktop-paragraph">
                Basic SSL validation
              </p>
            </li>
          </ul>
        </div>
        <div className="mt-8">
          <a
            href="https://app.urlcheckr.com/signup"
            className="flex items-center justify-center w-full py-4 text-white rounded-lg bg-slate-blue text-desktop-paragraph filter hover:brightness-125"
          >
            <span>Sign up</span>
          </a>
        </div>
      </div>
    </div>
  );
};

// const RightColumn = ({ isYearly }) => (
//   <div className="w-full relative p-[32px] rounded-3xl bg-white">
//     <div>
//       <div className="text-center">
//         <div className="flex items-center justify-center w-12 h-12 m-auto rounded-full bg-slate-blue">
//           <img src={proSvg} alt="Growth" />
//         </div>
//         <div className="mt-2 ml-4">
//           <h4 className="text-xl font-medium"> Pro </h4>
//           <p className="max-w-[243px] m-auto mt-2">
//             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elit.
//           </p>
//           <p className="mt-2 font-bold text-desktop-paragraph text-slate-body">
//             {isYearly ? "$65/mo" : "$107/mo"}
//           </p>
//         </div>
//       </div>
//       <div className="flex justify-center">
//         <ul className="">
//           <li className="flex items-center justify-around mt-4">
//             <CheckMark className="w-1/6 h-4 text-slate-blue" />
//             <p className="w-5/6 text-desktop-paragraph">
//               Lorem ipsum dolor sit amet.
//             </p>
//           </li>
//           <li className="flex items-center justify-around mt-4">
//             <CheckMark className="w-1/6 h-4 text-slate-blue" />
//             <p className="w-5/6 text-desktop-paragraph">
//               Lorem ipsum dolor sit amet.
//             </p>
//           </li>
//           <li className="flex items-center justify-around mt-4">
//             <CheckMark className="w-1/6 h-4 text-slate-blue" />
//             <p className="w-5/6 text-desktop-paragraph">
//               Lorem ipsum dolor sit amet.
//             </p>
//           </li>
//           <li className="flex items-center justify-around mt-4">
//             <CheckMark className="w-1/6 h-4 text-slate-blue" />
//             <p className="w-5/6 text-desktop-paragraph">
//               Lorem ipsum dolor sit amet.
//             </p>
//           </li>
//           <li className="flex items-center justify-around mt-4">
//             <CheckMark className="w-1/6 h-4 text-slate-blue" />
//             <p className="w-5/6 text-desktop-paragraph">
//               Lorem ipsum dolor sit amet.
//             </p>
//           </li>
//         </ul>
//       </div>
//       <div className="mt-8">
//         <button className="flex items-center justify-center w-full py-4 border-2 rounded-lg text-slate-blue border-slate-blue text-desktop-paragraph filter hover:bg-slate-blue hover:text-white">
//           <span>Secondary</span>
//         </button>
//       </div>
//     </div>
//   </div>
// );

const PricingTable2 = () => {
  const [isYearly, setIsYearly] = useState(true);

  return (
    <section className="bg-slate-light font-dm-sans">
      <div className="md:m-auto max-w-default py-12 md:py-[90px] mx-6">
        <div className="text-center">
          <h2 className="font-medium text-slate-headline text-mobile-h2 lg:text-desktop-h2">
            Pricing
          </h2>
          <div className="relative mt-7">
            <PricingTab isYearly={isYearly} setIsYearly={setIsYearly} />
          </div>
          <div className="mt-[60px]">
            <div className="flex flex-col justify-center space-y-4 text-left md:space-y-0 md:flex-row md:space-x-[30px]">
              <LeftColumn isYearly={isYearly} />
              <CenterColumn isYearly={isYearly} />
              {/* <RightColumn isYearly={isYearly} /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export { PricingTable2 };
