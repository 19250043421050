import React from "react";

const TryItFreeButton = () => (
  <a
    href="https://app.urlcheckr.com/signup"
    className="flex items-center justify-center px-12 py-4 space-x-3 rounded-lg bg-slate-blue hover:brightness-125 transition mt-6"
  >
    <span className="text-white font-medium text-lg">Sign up for free</span>
  </a>
);

const CTA9 = () => (
  <section className="bg-mesh py-[200px] font-dm-sans">
    <div className="py-12 mx-6 md:py-[90px] md:m-auto max-w-default">
      <div className="flex flex-col items-center justify-center text-center">
        <h2 className="font-bold leading-tight text-desktop-h2 text-white">
          Start with Urlcheckr today.
          <br />
          <span className="opacity-60">No credit card required.</span>
        </h2>
        <TryItFreeButton />
        <div className="w-full mt-8 md:w-auto md:mt-0"></div>
      </div>
    </div>
  </section>
);

export { CTA9 };
