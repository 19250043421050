import React from "react";
import cartSvg from "../../images/features/cart.svg";
import MenuIcon from "@heroicons/react/solid/MenuIcon";
import LinkIcon from "@heroicons/react/solid/LinkIcon";
import ShieldExclamationIcon from "@heroicons/react/solid/ShieldExclamationIcon";
import DocumentDownloadIcon from "@heroicons/react/solid/DocumentDownloadIcon";

const FeatureBlock = ({ img, heading, description, children }) => (
  <div className="md:w-2/4 mt-6 text-center p-9 md:min-w-[362px] rounded-3xl bg-slate-light">
    <div className="w-12 h-12 p-3 m-auto rounded-full bg-slate-orange text-white">
      {children}
    </div>
    <h5 className="mt-4 text-xl font-medium"> {heading}</h5>
    <p className="mt-4 font-normal text-desktop-paragraph"> {description}</p>
  </div>
);

const Feature18 = () => {
  return (
    <section className="bg-white font-dm-sans py-12 md:py-[90px]">
      <div className="m-auto max-w-default">
        <div className="mt-6">
          <div className="md:flex gap-6">
            <FeatureBlock
              heading={"Custom headers"}
              description={
                "Set custom headers like Authorization, Cache-Control or Content-Type for your requests to test different server responses."
              }
            >
              <MenuIcon className="h-6 w-6"></MenuIcon>
            </FeatureBlock>
            <FeatureBlock
              heading={"Follow redirects"}
              description={
                "Check up to 5 redirects and analyse the server response headers, status code and more."
              }
            >
              <LinkIcon className="h-6 w-6"></LinkIcon>
            </FeatureBlock>
          </div>
          <div className="md:flex gap-6">
            <FeatureBlock
              heading={"Add schemes"}
              description={
                "Save time by automatically add missing http or https schemes while importing urls."
              }
            >
              <ShieldExclamationIcon className="h-6 w-6"></ShieldExclamationIcon>
            </FeatureBlock>
            <FeatureBlock
              heading={"Import/export to CSV"}
              description={
                "Import/export your urls from and to CSV in a matter of seconds."
              }
            >
              <DocumentDownloadIcon className="h-6 w-6"></DocumentDownloadIcon>
            </FeatureBlock>
          </div>
        </div>
      </div>
    </section>
  );
};

export { Feature18 };
