import React from "react";
import { CTA9 } from "../components/CTA";
import { Feature18 } from "../components/Features";
import { Footer3 } from "../components/Footers";
import { Hero2 } from "../components/Heros";
import { PricingTable2 } from "../components/PricingTables";
import Head from "../head";

// markup
const IndexPage = () => {
  return (
    <main>
      <Head title="" />
      <Hero2 />
      <section id="features">
        <Feature18 />
      </section>
      <section id="pricing">
        <PricingTable2 />
      </section>

      <CTA9 />

      <Footer3 />
    </main>
  );
};

export default IndexPage;
